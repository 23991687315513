import redaxios from 'axios';
import { update, addItem, addItems } from '@/api/cart';

// general events
import { ITEM_PROPERTY_BUNDLE_KEY, ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT } from '@/store/minicart/bundles/constants';
import { EVENT_MINICART_CLOSE, EVENT_MINICART_OPEN } from '@/store/events';
import {
    ITEM_PROPERTY_BOX_HANDLE,
    ITEM_PROPERTY_FREQUENCY_INTERVAL, ITEM_PROPERTY_FREQUENCY_UNIT,
    ITEM_PROPERTY_INVETERATE_SUBSCRIPTION, PURCHASE_OPTION_ONETIME,
} from '@/store/minicart/boxes/constants';

import Swiper, { Autoplay, Navigation } from 'swiper';
import { isProductHasOnlyDefaultVariant } from '@/api/helpers/product';
import { ITEM_PROPERTY_PURCHASE_OPTION, ITEM_PROPERTY_SELLING_PLAN } from '@/store/minicart/shopify/cart/constants';
import { windowVariableLoaded } from '@/helpers/wishlist';
import { formatMoney } from '@shopify/theme-currency';
import Cookies from 'js-cookie';

export const REBUY_INIT = 'rebuy.init';

export const REBUY_BEFORE_LOADED_EVENT = 'rebuy.beforeLoaded';
export const REBUY_LOADED_EVENT = 'rebuy.loaded';

// cart events
export const REBUY_CART_READY_EVENT = 'rebuy:cart.ready';
export const REBUY_CART_ADD_EVENT = 'rebuy:cart.add';
export const REBUY_CART_CHANGE_EVENT = 'rebuy:cart.change';
export const REBUY_CART_ENRICHED_EVENT = 'rebuy:cart.enriched';

// smart cart events
export const REBUY_SMART_CART_INIT_EVENT = 'rebuy:smartcart.init';
export const REBUY_SMART_CART_READY_EVENT = 'rebuy:smartcart.ready';
export const REBUY_SMART_CART_SHOW_EVENT = 'rebuy:smartcart.show';
export const REBUY_SMART_CART_HIDE_EVENT = 'rebuy:smartcart.hide';
export const REBUY_SMART_CART_ITEM_INCREASE_EVENT = 'rebuy:smartcart.line-item-increase';
export const REBUY_SMART_CART_ITEM_DECREASE_EVENT = 'rebuy:smartcart.line-item-decrease';
export const REBUY_SMART_CART_ITEM_REMOVED_EVENT = 'rebuy:smartcart.line-item-removed';
export const REBUY_SMART_CART_ITEM_SWITCH_TO_SUBSCRIPTION_EVENT = 'rebuy:smartcart.item-switch-to-subscription';
export const REBUY_SMART_CART_ITEM_SWITCH_TO_ONETIME_EVENT = 'rebuy:smartcart.item-switch-to-one-time';

function initAnnouncementSliders() {
    const interval = setInterval(() => {
        const sliders = Array.from(document.querySelectorAll(`[data-minicart-announcement-bar-slider]`));
        if (!sliders) {
            return;
        }

        if (!sliders?.length) {
            return;
        }

        sliders?.forEach((slider) => {
            new Swiper(slider, {
                loop: true,
                autoplay: {
                    delay: 4000,
                },
                observer: true,
                resizeObserver: true,
                watchSlidesProgress: true,
                watchOverflow: true,
                modules: [Autoplay],
            });
        });

        clearInterval(interval);
    }, 100);
}

function initRecommendationSliders() {
    const interval = setInterval(() => {
        const sliders = Array.from(document.querySelectorAll(`[data-rebuy-recommendation-slider-container]`));
        if (!sliders) {
            return;
        }

        if (!sliders?.length) {
            return;
        }

        sliders?.forEach((slider) => {
            const sliderElement = slider.querySelector(`[data-rebuy-recommendation-slider]`);
            if (!sliderElement) {
                return;
            }

            const nextEl = slider.querySelector(`[data-arrow-next]`);
            const prevEl = slider.querySelector(`[data-arrow-prev]`);

            new Swiper(sliderElement, {
                initialSlide: 0,
                slidesPerView: 1,
                spaceBetween: 0,
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 12,
                    },
                },
                observer: true,
                resizeObserver: true,
                watchSlidesProgress: true,
                watchOverflow: true,
                modules: [
                    Navigation,
                ],
                navigation: {
                    nextEl,
                    prevEl,
                },
            });
        });

        clearInterval(interval);
    }, 100);
}

document.addEventListener(REBUY_SMART_CART_READY_EVENT, () => {
    initAnnouncementSliders();
});

window.initAnnouncementSliders = initAnnouncementSliders;

document.addEventListener(REBUY_INIT, () => {
    initRecommendationSliders();
});

document.addEventListener(REBUY_SMART_CART_SHOW_EVENT, () => {
    initRecommendationSliders();
    initAnnouncementSliders();
});

document.addEventListener('rebuy.refresh', () => {
    initRecommendationSliders();
});

document.addEventListener('rebuy.show', () => {
    initRecommendationSliders();
});

let upsellInitialized = false;

const RebuyUtils = {
    products: {},
    tier_selected_variants: {},

    get discount_settings() {
        const element = document.querySelector('[data-discount-settings]');
        if (!element) {
            return null;
        }

        const value = element?.innerHTML?.trim();
        if (!value) {
            return null;
        }

        try {
            return JSON.parse(value);
        } catch (error) {}

        return null;
    },

    isCreateOrEditABoxPage() {
        const params = new URLSearchParams(window.location.search);

        const view = params.get('view');

        return view === 'create-box' || view === 'edit-box';
    },

    currency() {
        return window.Rebuy.Cart.getCurrency() || 'USD';
    },

    country() {
        return window.Shopify.country_code || 'US';
    },

    icon(name, { classes = '', viewBox = '', attributes = '' } = {
        classes: '',
        viewBox: '',
        attributes: '',
    }) {
        return `<svg class="icon icon-${name} ${classes}" ${viewBox ? `viewBox="${viewBox}"` : ''} preserveAspectRatio="xMinYMid meet" aria-hidden="true" ${attributes}><use href="${window?.theme?.spriteUrl.replace('[[name]]', name) || ''}"></svg>`;
    },
    is_free_gift() {
        return false;
    },
    is_bundle_parent(item) {
        if (!item) {
            return false;
        }

        const properties = item?.properties;
        if (!properties) {
            return false;
        }

        const keys = Object.keys(properties);
        if (!keys) {
            return false;
        }

        if (!keys?.length) {
            return false;
        }

        return !!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT);
    },
    is_bundle_child(item) {
        if (!item) {
            return false;
        }

        const properties = item?.properties;
        if (!properties) {
            return false;
        }

        const keys = Object.keys(properties);
        if (!keys) {
            return false;
        }

        if (!keys?.length) {
            return false;
        }

        if (!!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT)) {
            return false;
        }

        return !!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY);
    },
    is_monthly_box_item(item) {
        if (!item) {
            return false;
        }

        const selling_plan_allocation = item?.selling_plan_allocation;
        if (!selling_plan_allocation) {
            return false;
        }

        return !!selling_plan_allocation?.selling_plan;
    },
    bundle_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            return !!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY);
        }) || [];
    },

    bundles() {
        const bundle_items = this.bundle_items();
        if (!bundle_items) {
            return [];
        }

        if (!bundle_items?.length) {
            return [];
        }

        const parent_items = bundle_items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            return !!keys?.includes(ITEM_PROPERTY_BUNDLE_MAIN_PRODUCT);
        });

        if (!parent_items) {
            return [];
        }

        if (!parent_items?.length) {
            return [];
        }

        return parent_items?.map((parent_item) => {
            const parent_properties = parent_item?.properties;

            if (!parent_properties) {
                return parent_item;
            }

            const parent_keys = Object.keys(parent_properties);
            if (!parent_keys) {
                return parent_item;
            }

            if (!parent_keys?.length) {
                return parent_item;
            }

            const parent_bundle_key = parent_properties[ITEM_PROPERTY_BUNDLE_KEY];
            if (!parent_bundle_key) {
                return parent_item;
            }

            const item_components = bundle_items?.filter((bundle_item) => {
                const bundle_item_properties = bundle_item?.properties;
                if (!bundle_item_properties) {
                    return false;
                }

                const keys = Object.keys(bundle_item_properties);
                if (!keys) {
                    return false;
                }

                if (!keys?.length) {
                    return false;
                }

                if (!keys?.includes(ITEM_PROPERTY_BUNDLE_KEY)) {
                    return false;
                }

                const bundle_key = bundle_item_properties[ITEM_PROPERTY_BUNDLE_KEY];
                if (!bundle_key) {
                    return false;
                }

                return bundle_key === parent_bundle_key;
            });

            if (!item_components) {
                return parent_item;
            }

            if (!item_components?.length) {
                return parent_item;
            }

            return {
                ...parent_item,
                bundle_key: parent_bundle_key,
                has_components: true,
                item_components,
                item_components_count: item_components?.length,
                final_line_price: (parent_item?.final_line_price || 0) + ((item_components || [])?.filter((item) => {
                    return item.key !== parent_item.key;
                })?.reduce((final_line_price, item) => {
                    return final_line_price + (item?.final_line_price || 0);
                }, 0) || 0),
                original_line_price: (parent_item?.original_line_price || 0) + ((item_components || [])?.filter((item) => {
                    return item.key !== parent_item.key;
                })?.reduce((original_line_price, item) => {
                    return original_line_price + (item?.original_line_price || 0);
                }, 0) || 0),
            };
        });
    },

    get_bundle(bundle_key) {
        const bundles = this.bundles();
        if (!bundles) {
            return null;
        }

        if (!bundles?.length) {
            return null;
        }

        return bundles?.find((bundle) => {
            return bundle?.bundle_key === bundle_key;
        }) || null;
    },

    subscription_items() {
        const {
            membershipProductTags = [],
        } = this.discount_settings;

        if (!membershipProductTags) {
            return [];
        }

        if (!membershipProductTags?.length) {
            return [];
        }

        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            const tags = item?.product?.tags?.split(',')?.map(tag => {
                return tag?.toLowerCase()?.trim();
            }) || [];

            if (!tags) {
                return false;
            }

            if (!tags?.length) {
                return false;
            }

            if (!item?.selling_plan_allocation) {
                return false;
            }

            return !membershipProductTags?.map((tag) => {
                return tag?.toLowerCase();
            })?.map((tag) => {
                return !!tags?.includes(tag);
            })?.includes(true);
        }) || [];
    },

    subscription_items_total() {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return 0;
        }

        if (!subscription_items?.length) {
            return 0;
        }

        return subscription_items?.reduce((total, item) => {
            return total + (item?.final_line_price || 0);
        }, 0) || 0;
    },

    subscription_items_total_usd() {
        return window.Currency.convert(this.subscription_items_total() / 100, this.currency(), 'USD') * 100;
    },

    subscription_lash_items() {
        const {
            subscriptionTierProductTags = [],
        } = this.discount_settings;

        if (!subscriptionTierProductTags) {
            return [];
        }

        if (!subscriptionTierProductTags?.length) {
            return [];
        }

        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return [];
        }

        if (!subscription_items?.length) {
            return [];
        }

        return subscription_items?.filter((item) => {
            const tags = item?.product?.tags?.split(',')?.map(tag => {
                return tag.toLowerCase().trim();
            }) || [];

            if (!tags) {
                return false;
            }

            if (!tags?.length) {
                return false;
            }

            return !!subscriptionTierProductTags?.map((tag) => {
                return tag?.toLowerCase();
            })?.map((tag) => {
                return !!tags?.includes(tag);
            })?.includes(true);
        }) || [];
    },

    subscription_lash_items_subtotal() {
        const subscription_lash_items = this.subscription_lash_items();
        if (!subscription_lash_items) {
            return 0;
        }

        if (!subscription_lash_items?.length) {
            return 0;
        }

        return subscription_lash_items?.reduce((subtotal, item) => {
            return subtotal + (item?.final_line_price || 0);
        }, 0) || 0;
    },

    subscription_lash_items_subtotal_usd() {
        return window.Currency.convert(this.subscription_lash_items_subtotal() / 100, this.currency(), 'USD') * 100;
    },

    subscription_lash_items_count() {
        const subscription_lash_items = this.subscription_lash_items();
        if (!subscription_lash_items) {
            return 0;
        }

        if (!subscription_lash_items?.length) {
            return 0;
        }

        return subscription_lash_items?.reduce((count, item) => {
            return count + (item?.quantity || 0);
        }, 0) || 0;
    },

    shippable_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            return !!item?.requires_shipping;
        }) || [];
    },

    unshippable_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            return !item?.requires_shipping;
        }) || [];
    },

    shippable_items_subtotal() {
        const shippable_items = this.shippable_items();
        if (!shippable_items) {
            return 0;
        }

        if (!shippable_items?.length) {
            return 0;
        }

        return shippable_items?.reduce((subtotal, item) => {
            return subtotal + (item?.final_line_price || 0);
        }, 0) || 0;
    },

    unshippable_items_subtotal() {
        const unshippable_items = this.unshippable_items();
        if (!unshippable_items) {
            return 0;
        }

        if (!unshippable_items?.length) {
            return 0;
        }

        return unshippable_items?.reduce((subtotal, item) => {
            return subtotal + (item?.final_line_price || 0);
        }, 0) || 0;
    },

    shippable_items_subtotal_usd() {
        return window.Currency.convert(this.shippable_items_subtotal() / 100, this.currency(), 'USD') * 100;
    },

    unshippable_items_subtotal_usd() {
        return window.Currency.convert(this.unshippable_items_subtotal() / 100, this.currency(), 'USD') * 100;
    }, 

    monthly_box_items() {
        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        return items?.filter((item) => {
            const tags = item?.product?.tags?.split(',')?.map((tag) => {
                return tag?.toLowerCase()?.trim();
            }) || [];

            const properties = item.properties || {};

            return ![
                (!!item?.selling_plan_allocation || !!properties[ITEM_PROPERTY_BOX_HANDLE]),
                !tags?.includes('inveterate-product'),
                !tags?.includes('recharge-bundle'),
                !Boolean(properties['_rb_id']),
                !Boolean(properties['_subscription_upsell']),
            ].includes(false);
        }) || [];
    },

    boxes() {
        const boxes = [
            {
                key: 'monthly-box',
                title: 'Monthly Box',
                url: `/account?view=create-box`,
                image: 'https://cdn.shopify.com/s/files/1/1832/9335/files/luxe-box_541391be-1b84-4bc8-a536-7f4aabff24eb.jpg',
                type: 'default',
                items: this.monthly_box_items(),
            },
        ]?.map((box) => {
            const selling_plan = (box.items || []).reduce((selected_selling_plan, item) => {
                const selling_plan_allocation = item?.selling_plan_allocation;
                if (!selling_plan_allocation) {
                    return selected_selling_plan;
                }

                const selling_plan = selling_plan_allocation?.selling_plan;
                if (!selling_plan) {
                    return selected_selling_plan;
                }

                const name = selling_plan?.name;
                if (!name) {
                    return selected_selling_plan;
                }

                const regex = /(?<order_interval_frequency>\d+)(\s+)?(?<order_interval_unit_type>day|week|month|year)/gm;
                const rechargeData = regex?.exec(name?.toLowerCase())?.groups;

                const order_interval_frequency = rechargeData?.order_interval_frequency || null;
                if (!order_interval_frequency) {
                    return selected_selling_plan;
                }

                const order_interval_unit_type = rechargeData?.order_interval_unit_type || null;
                if (!order_interval_unit_type) {
                    return selected_selling_plan;
                }

                return {
                    ...selling_plan,
                    name: `Delivered every ${order_interval_frequency} ${order_interval_unit_type}${order_interval_frequency > 0 ? `s` : ``}`,
                    order_interval_frequency,
                    order_interval_unit_type,
                };
            }, null);

            return {
                ...box,
                selling_plan,
                final_line_price: (box.items || [])?.reduce((final_line_price, item) => {
                    return final_line_price + (item?.final_line_price || 0);
                }, 0) || 0,
                original_line_price: (box.items || [])?.reduce((original_line_price, item) => {
                    return original_line_price + (item?.original_line_price || 0);
                }, 0) || 0,
                order_interval_frequency: selling_plan?.order_interval_frequency,
                order_interval_unit_type: selling_plan?.order_interval_unit_type,
                count: box?.items?.reduce((count, item) => {
                    return count + (item?.quantity || 0);
                }, 0) || 0,
            };
        })?.filter((box) => {
            return !!box?.selling_plan;
        }) || [];

        if (!boxes) {
            return [];
        }

        if (!boxes?.length) {
            return [];
        }

        return boxes;
    },

    box() {
        const boxes = this.boxes();
        if (!boxes) {
            return null;
        }

        if (!boxes?.length) {
            return null;
        }

        const box = boxes[0];
        if (!box) {
            return null;
        }

        return box;
    },

    regular_items() {
        const {
            membershipProductTags = [],
        } = this.discount_settings;

        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        const regular_items = items?.filter((item) => {
            const tags = item?.product?.tags?.split(',')?.map((tag) => {
                return tag?.toLowerCase()?.trim();
            }) || [];

            const properties = item.properties || {};

            if (!!membershipProductTags?.map((tag) => {
                return tag?.toLowerCase();
            })?.map((tag) => {
                return tags?.includes(tag);
            })?.includes(true)) {
                return true;
            }

            if (!![
                (!!item.selling_plan_allocation || !!properties[ITEM_PROPERTY_BOX_HANDLE]),
                !!properties[ITEM_PROPERTY_BUNDLE_KEY],
            ]?.includes(true)) {
                return false;
            }

            return true;
        }) || [];

        if (!regular_items) {
            return [];
        }

        if (!regular_items?.length) {
            return [];
        }

        return regular_items;
    },

    compare_at_price(item) {
        const variant_id = item?.variant_id;
        if (!variant_id) {
            return item?.original_line_price || 0;
        }

        const product = item?.product;
        if (!product) {
            return item?.original_line_price || 0;
        }

        const variants = product?.variants;
        if (!variants) {
            return item?.original_line_price || 0;
        }

        if (!variants?.length) {
            return item?.original_line_price || 0;
        }

        const variant = variants?.find((variant) => {
            return variant?.id === variant_id;
        });

        if (!variant) {
            return item?.original_line_price || 0;
        }

        const currency_code = this.currency() || "USD";
        if (!currency_code) {
            return item?.original_line_price || 0;
        }

        const presentment_prices = variant?.presentment_prices;
        if (!presentment_prices) {
            return item?.original_line_price || 0;
        }

        const presentment_compare_at_price = parseFloat(presentment_prices?.find((prices) => {
            if (!prices) {
                return false;
            }

            const compare_at_price = prices?.compare_at_price;
            if (!compare_at_price) {
                return false;
            }

            return compare_at_price?.currency_code?.toLowerCase() === currency_code?.toLowerCase();
        })?.compare_at_price?.amount || "0.0");

        if (!presentment_compare_at_price) {
            return item?.original_line_price || 0;
        }

        return (presentment_compare_at_price || 0) * (item?.quantity || 1) * 100;
    },

    setting(name) {
        const settings = window?.Theme?.settings;
        if (!settings) {
            return null;
        }

        return settings[name] || null;
    },

    translate(...props) {
        return window.Theme.translate(...props);
    },

    upsellInit(products) {
        if (upsellInitialized) {
            return;
        }

        products.forEach(product => {
            const productId = product.id;

            window.RebuyUtils.products[productId] = {
                selected_variant: product?.selected_variant,
                option1: product?.selected_variant?.option1,
                option2: product?.selected_variant?.option2,
                option3: product?.selected_variant?.option3,
            };
        });

        upsellInitialized = true;
    },

    findVariantIdByOptions(product, optionIndex, selectedOption) {
        const productId = product.id * 1;

        if (window.RebuyUtils.products[productId]) {
            window.RebuyUtils.products[productId][optionIndex] = selectedOption;
        } else {
            console.error('Product not found in RebuyUtils.products');
            return null;
        }

        const selectedOptionsLower = [
            window.RebuyUtils.products[productId]?.option1,
            window.RebuyUtils.products[productId]?.option2,
            window.RebuyUtils.products[productId]?.option3,
        ].map(option => option?.toLowerCase());

        const variant = product?.variants?.find(variant => {
            return [variant?.option1, variant?.option2, variant?.option3].every((option, index) => {
                return option?.toLowerCase() === selectedOptionsLower[index];
            });
        });

        if (!variant) {
            console.error('No matching variant found for selected options:', selectedOption);
            return null;
        }

        window.RebuyUtils.products[productId].selected_variant = variant;
        window.RebuyUtils.updateOptionSelectors(product, variant);
        product.selected_variant = variant;
        return variant.id;
    },


    updateOptionSelectors(product, currentVariant) {
        if (!product) {
            return;
        }

        const variants = product?.variants;
        if (!variants) {
            return;
        }

        if (!variants?.length) {
            return;
        }

        if (!currentVariant) {
            return;
        }

        if (product?.options?.length === 1) {
            return;
        }

        let parentEl = null;

        document.querySelectorAll('.upsell-widget__product').forEach((productBlock) => {
                if ((productBlock.dataset.productId * 1) === product.id) {
                    parentEl = productBlock;
                }
            },
        );

        const option1ValueSelectors = parentEl.querySelectorAll(`.upsell-widget__option-select#option1 > option`);
        if (!option1ValueSelectors) {
            return;
        }

        if (!option1ValueSelectors?.length || option1ValueSelectors?.length === 1) {
            return;
        }

        option1ValueSelectors?.forEach((el) => {
            el?.classList?.remove('hide');
        });

        const option2 = currentVariant?.option2;
        const option3 = currentVariant?.option3;

        const existing_option1_values = [
            ...new Set(variants?.filter((variant) => ![
                option2 ? variant?.option2 === option2 : false,
                option3 ? variant?.option3 === option3 : false,
            ]?.includes(false))?.map((variant) => variant?.option1) || []),
        ];

        option1ValueSelectors?.forEach((el) => {
            const value = el?.value || '';
            if (!value) {
                return;
            }

            if (!existing_option1_values?.includes(value) || product?.options?.length === 1) {
                el?.classList?.add('hide');
            }
        });

        // Filter Option2
        const option2ValueSelectors = parentEl.querySelectorAll(`.upsell-widget__option-select#option2 > option`);
        if (!option2ValueSelectors?.length) {
            return;
        }

        option2ValueSelectors?.forEach((el) => {
            el?.classList?.remove('hide');
        });

        const existing_option2_values = [
            ...new Set(variants?.filter((variant) => variant?.option1 === currentVariant?.option1)?.map((variant) => variant?.option2) || []),
        ];

        option2ValueSelectors?.forEach((el) => {
            const value = el?.value || '';
            if (!value || !existing_option2_values?.includes(value)) {
                el?.classList?.add('hide');
            }
        });

        // Filter Option3
        const option3ValueSelectors = parentEl.querySelectorAll(`.upsell-widget__option-select#option3 > option`);
        if (!option3ValueSelectors?.length) {
            return;
        }

        option3ValueSelectors?.forEach((el) => {
            el?.classList?.remove('hide');
        });

        const existing_option3_values = [
            ...new Set(variants?.filter((variant) =>
                variant?.option1 === currentVariant?.option1 && variant?.option2 === currentVariant?.option2,
            )?.map((variant) => variant?.option3) || []),
        ];

        option3ValueSelectors.forEach((el) => {
            const value = el?.value || '';
            if (!existing_option3_values.includes(value)) {
                el?.classList?.add('hide');
            }
        });
    },

    async openModal(name, { teleport = 'body' } = {
        teleport: 'body',
    }) {
        const html = await redaxios.get('/', {
            params: {
                section_id: name,
            },
        }).then((response) => {
            return response.data;
        });

        if (!html) {
            return;
        }

        const modal = document.createElement('div');

        modal.innerHTML = html || '';

        const wrapper = document.querySelector(teleport) || document.body;
        if (!wrapper) {
            return;
        }

        wrapper?.appendChild(modal);
    },

    async remove_monthly_box() {
        const monthly_box_items = this.monthly_box_items();
        if (!monthly_box_items) {
            return;
        }

        if (!monthly_box_items?.length) {
            return;
        }

        await update(monthly_box_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});
    },

    async remove_bundle(bundle_key) {
        const bundle_items = this.bundle_items();
        if (!bundle_items) {
            return;
        }

        if (!bundle_items?.length) {
            return;
        }

        const filtered_bundle_items = bundle_items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            const item_bundle_key = properties[ITEM_PROPERTY_BUNDLE_KEY];
            if (!item_bundle_key) {
                return false;
            }

            return item_bundle_key === bundle_key;
        });

        if (!filtered_bundle_items) {
            return;
        }

        if (!filtered_bundle_items?.length) {
            return;
        }

        await update(filtered_bundle_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});
    },

    inveterate_items() {
        const {
            membershipProductTags = [],
        } = this.discount_settings;

        if (!membershipProductTags) {
            return [];
        }

        if (!membershipProductTags?.length) {
            return [];
        }

        const items = window.Rebuy.Cart.items();
        if (!items) {
            return [];
        }

        if (!items?.length) {
            return [];
        }

        const downcasedMembershipProductTags = membershipProductTags?.map((tag) => {
            return tag?.toLowerCase();
        });

        const filtered_items = items?.filter((item) => {
            const product_tags = item?.product?.tags?.split(',')?.reduce((tags, tag) => {
                const downcasedTag = tag?.toLowerCase()?.trim();
                if (!downcasedTag) {
                    return tags;
                }

                return [
                    ...(tags || []),
                    downcasedTag,
                ];
            }, []) || [];

            if (!product_tags) {
                return false;
            }

            if (!product_tags?.length) {
                return false;
            }

            return !!downcasedMembershipProductTags?.map((tag) => {
                return !!product_tags?.includes(tag);
            })?.includes(true);
        }) || [];

        if (!filtered_items) {
            return [];
        }

        if (!filtered_items?.length) {
            return [];
        }

        return filtered_items;
    },

    membership_items_tags() {
        const inveterate_items = this.inveterate_items();
        if (!inveterate_items) {
            return [];
        }

        if (!inveterate_items?.length) {
            return [];
        }

        return inveterate_items?.reduce((product_tags, item) => {
            const tags = item?.product?.tags?.split(',')?.map((tag) => {
                return tag?.toLowerCase()?.trim();
            })?.filter((tag) => {
                return !!tag?.startsWith('inveterate-tier');
            }) || [];

            if (!tags) {
                return product_tags;
            }

            if (!tags?.length) {
                return product_tags;
            }

            return [
                ...(product_tags || []),
                ...(tags || []),
            ];
        }, []) || [];
    },

    inveterate_product_already_in_cart() {
        const inveterate_items = this.inveterate_items();
        if (!inveterate_items) {
            return false;
        }

        return !!inveterate_items?.length;
    },

    inveterate_product() {
        const inveterate = window['inveterate'] || {};
        if (!inveterate) {
            return null;
        }

        const properties = inveterate?.properties;
        if (!properties) {
            return null;
        }

        const product = properties?.product;
        if (!product) {
            return null;
        }

        return product;
    },

    inveterate_variant() {
        const inveterate_product = this.inveterate_product();
        if (!inveterate_product) {
            return null;
        }

        const variants = inveterate_product?.variants;
        if (!variants) {
            return null;
        }

        if (!variants?.length) {
            return null;
        }

        return variants[0];
    },

    inveterate_product_image() {
        const product = this.inveterate_product();
        if (!product) {
            return null;
        }

        const medias = product?.media;
        if (!medias) {
            return null;
        }

        if (!medias?.length) {
            return null;
        }

        const media = medias[0];
        if (!media) {
            return null;
        }

        return media;
    },

    inveterate_variant_available() {
        const inveterate_variant = this.inveterate_variant();
        if (!inveterate_variant) {
            return false;
        }

        return !!inveterate_variant?.available;
    },

    async add_membership(event) {
        const button = event?.target?.closest('[data-membership-button]') || event?.target;

        const inveterate_product = this.inveterate_product();
        const inveterate_variant = this.inveterate_variant();

        if (!inveterate_variant) {
            return;
        }

        const selling_plan_groups = inveterate_product?.selling_plan_groups;
        if (!selling_plan_groups) {
            return;
        }

        if (!selling_plan_groups?.length) {
            return;
        }

        const selling_plan_group = selling_plan_groups[0];
        if (!selling_plan_group) {
            return;
        }

        const selling_plans = selling_plan_group?.selling_plans;
        if (!selling_plans) {
            return;
        }

        if (!selling_plans?.length) {
            return;
        }

        const selling_plan = selling_plans[0];
        if (!selling_plan) {
            return;
        }

        const selling_plan_id = selling_plan?.id;

        if (button) {
            button?.classList?.add('loading');
            button.disabled = true;
        }

        try {
            await addItem({
                id: inveterate_variant.id,
                quantity: 1,
                properties: {
                    [ITEM_PROPERTY_INVETERATE_SUBSCRIPTION]: true,
                },
                ...(selling_plan_id ? {
                    selling_plan: selling_plan_id,
                } : {}),
            });
        } catch (error) {

        } finally {
            if (button) {
                button?.classList?.remove('loading');
                button.disabled = false;
            }
        }
    },

    async remove_inveterate_items() {
        const inveterate_items = this.inveterate_items();
        if (!inveterate_items) {
            return;
        }

        if (!inveterate_items?.length) {
            return;
        }

        await update(inveterate_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});
    },

    async toggle_inveterate_product() {
        if (this.inveterate_product_already_in_cart()) {
            return this.remove_inveterate_items();
        }

        return this.add_membership();
    },

    async updateItemDeliveryFrequency(item, order_interval_frequency, order_interval_unit_type, ...callbacks) {
        delete item.properties['_attribution'];
        delete item.properties['_source'];
        delete item.properties['_discount_value'];

        item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = order_interval_frequency;
        item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = order_interval_unit_type;

        return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, order_interval_frequency?.toString(), order_interval_unit_type?.toString(), ...callbacks);
    },

    subscription_options() {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        if (!subscription_items?.length) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        const subscription_item = subscription_items[0];
        if (!subscription_item) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        const product = subscription_item?.product;
        if (!product) {
            return {
                subscription_frequency: '30',
                subscription_interval: 'day',
            };
        }

        const { subscription_frequency = '30', subscription_interval = 'day' } = product || {
            subscription_frequency: '30',
            subscription_interval: 'day',
        };

        return {
            subscription_frequency,
            subscription_interval,
        };
    },

    async switchToSubscription(item) {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        if (!subscription_items?.length) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const subscription_item = subscription_items[0];
        if (!subscription_item) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const product = subscription_item?.product;
        if (!product) {
            item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = '30';
            item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = 'day';

            return this.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const { subscription_frequency = '30', subscription_interval = 'day' } = product || {
            subscription_frequency: '30',
            subscription_interval: 'day',
        };

        return this.updateItemDeliveryFrequency(item, subscription_frequency, subscription_interval);
    },

    subscriptionOptionLabel(item) {
        const box = this.box();
        if (!box) {
            return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, '30', 'day');
        }

        const { order_interval_frequency, order_interval_unit_type } = box || {
            order_interval_frequency: '30',
            order_interval_unit_type: 'day',
        };

        if (!order_interval_frequency) {
            return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, '30', 'day');
        }

        if (!order_interval_unit_type) {
            return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, order_interval_frequency?.toString(), 'day');
        }

        return window.Rebuy.SmartCart.updateItemDeliveryFrequency(item, order_interval_frequency?.toString(), order_interval_unit_type?.toString());
    },

    async updateMonthlyBoxDeliveryFrequency(order_interval_frequency) {
        const subscription_items = this.subscription_items();
        if (!subscription_items) {
            return [];
        }

        if (!subscription_items?.length) {
            return [];
        }

        const items = subscription_items?.reduce((updates, item) => {
            const selling_plan_id = item?.product?.selling_plan_groups?.reduce((selling_plans, selling_plan_group) => {
                return [
                    ...selling_plans,
                    ...(selling_plan_group?.selling_plans || []),
                ];
            }, [])?.find((selling_plan) => {
                return selling_plan?.order_interval_frequency?.toString() === order_interval_frequency?.toString();
            })?.id;

            return [
                ...updates,
                {
                    id: item.variant_id,
                    quantity: item.quantity,
                    properties: {
                        ...(item?.properties || {}),
                        [ITEM_PROPERTY_FREQUENCY_INTERVAL]: order_interval_frequency,
                        [ITEM_PROPERTY_FREQUENCY_UNIT]: 'day',
                        [ITEM_PROPERTY_SELLING_PLAN]: selling_plan_id,
                    },
                    ...(selling_plan_id ? {
                        selling_plan: selling_plan_id,
                    } : {}),
                },
            ];
        }, []) || [];

        await update(subscription_items?.reduce((updates, item) => {
            return {
                ...updates,
                [item?.key]: 0,
            };
        }, {}) || {});

        return addItems(items);
    },

    isProductHasOnlyDefaultVariant(product) {
        return isProductHasOnlyDefaultVariant(product);
    },

    async addUpsell(item) {
        delete item.properties['_attribution'];
        delete item.properties['_source'];

        const { subscription_frequency = '30', subscription_interval = 'day' } = this.subscription_options();

        item.properties[ITEM_PROPERTY_FREQUENCY_INTERVAL] = subscription_frequency;
        item.properties[ITEM_PROPERTY_FREQUENCY_UNIT] = subscription_interval;
        item.properties[ITEM_PROPERTY_PURCHASE_OPTION] = PURCHASE_OPTION_ONETIME;

        return window.Rebuy.Cart.addItem(item);
    },

    subtotal() {
        const subtotal = window.Rebuy.Cart.subtotal();
        if (!subtotal) {
            return 0;
        }

        if ([
            'JPY',
        ]?.includes(this.currency())) {
            return subtotal * 100;
        }

        return subtotal;
    },

    subtotal_usd() {
        return window.Currency.convert(this.subtotal() / 100, this.currency(), 'USD') * 100;
    },

    customer() {
        return window?.globals?.customer || null;
    },

    customer_tags() {
        const customer = this.customer();
        if (!customer) {
            return [];
        }

        const tags = customer?.tags;
        if (!tags) {
            return [];
        }

        if (!tags?.length) {
            return [];
        }

        return tags;
    },

    current_membership_tags() {
        return [
            ...(this.customer_tags() || []),
            ...(this.membership_items_tags() || []),
        ];
    },

    is_active_member_customer() {
        const {
            activeMemberCustomerTags = [],
            cancelledMemberCustomerTags = [],
        } = this.discount_settings;

        if (!activeMemberCustomerTags) {
            return false;
        }

        if (!activeMemberCustomerTags?.length) {
            return false;
        }

        const customer_tags = this.customer_tags();
        if (!customer_tags) {
            return false;
        }

        if (!customer_tags?.length) {
            return false;
        }

        const downcased_tags = customer_tags?.map((tag) => {
            return tag?.toLowerCase();
        }) || [];

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        if (!!cancelledMemberCustomerTags?.map((tag) => {
            return tag?.toLowerCase();
        })?.map((tag) => {
            return !!downcased_tags?.includes(tag);
        })?.includes(true)) {
            return false;
        }

        return !!activeMemberCustomerTags?.map((tag) => {
            return tag?.toLowerCase();
        })?.map((tag) => {
            return !!downcased_tags?.includes(tag);
        })?.includes(true);
    },

    membership_pricing_available() {
        const inveterate_product_already_in_cart = this.inveterate_product_already_in_cart();
        if (!!inveterate_product_already_in_cart) {
            return true;
        }

        return this.is_active_member_customer();
    },

    membership_shipping_pricing_available() {
        const {
            activeMemberShippingCustomerTags = [],
            cancelledMemberCustomerTags = [],
        } = this.discount_settings;

        const membership_items_tags = this.membership_items_tags();
        if (!!membership_items_tags?.length) {
            if (!!membership_items_tags?.map((tag) => {
                return !!activeMemberShippingCustomerTags?.includes(tag);
            })?.includes(true)) {
                return true;
            }

            return false;
        }

        const customer_tags = this.customer_tags();
        if (!customer_tags) {
            return false;
        }

        if (!customer_tags?.length) {
            return false;
        }

        const downcased_tags = customer_tags?.map((tag) => {
            return tag?.toLowerCase();
        }) || [];

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        if (!!cancelledMemberCustomerTags?.map((tag) => {
            return !!tag?.toLowerCase();
        })?.map((tag) => {
            return !!downcased_tags?.includes(tag);
        })?.includes(true)) {
            return false;
        }

        return !!activeMemberShippingCustomerTags?.map((tag) => {
            return tag?.toLowerCase();
        })?.map((tag) => {
            return !!downcased_tags?.includes(tag);
        })?.includes(true);
    },

    is_active_subscriber_customer() {
        const customer_tags = this.customer_tags();
        if (!customer_tags) {
            return false;
        }

        if (!customer_tags?.length) {
            return false;
        }

        const downcased_tags = customer_tags?.map((tag) => {
            return tag?.toLowerCase();
        }) || [];

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        return !!downcased_tags?.includes('Active Product Subscription'?.toLowerCase());
    },

    get rebuy_free_shipping_rules() {
        if (!window?.Rebuy?.SmartCart?.settings?.progress_bar?.enabled) {
            return [];
        }

        return window?.Rebuy?.SmartCart?.settings?.progress_bar?.bars?.filter((bar) => {
            return !!bar?.free_shipping_enabled;
        })?.reduce((tiers, bar) => {
            return [
                ...(tiers || []),
                ...(bar?.tiers || []),
            ];
        }, [])?.filter((tier) => {
            return tier?.type?.toLowerCase() === 'shipping';
        })?.reduce((conditions, tier) => {
            const presentment_currencies = tier?.presentment_currencies;
            if (!presentment_currencies) {
                return conditions;
            }

            const currencies = Object.keys(presentment_currencies);
            if (!currencies) {
                return conditions;
            }

            if (!currencies?.length) {
                return conditions;
            }

            return [
                ...(conditions || []),
                ...(currencies?.reduce((conditions, currency) => {
                    const threshold = presentment_currencies[currency];

                    if (!threshold) {
                        return conditions;
                    }

                    return [
                        ...conditions,
                        {
                            condition: this.currency().toLowerCase() === currency.toLowerCase(),
                            settings: {
                                property: 'subtotal',
                                threshold,
                                messages: {
                                    reached: 'Congrats! Your items ship for free',
                                    away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                                },
                            },
                        },
                    ];
                }, []) || []),
            ];
        }, []) || [];
    },

    free_shipping_rules() {
        return [
            // if more than 4 subscription lashes and country is US
            {
                condition: ![
                    this.country() === 'US',
                    this.subscription_lash_items_count() > 0,
                ]?.includes(false),
                settings: {
                    property: 'subscription_lash_items_count',
                    threshold: 4,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'Add <strong>{{ free_shipping_away_amount }}</strong> more lashes to your box to receive free shipping',
                    },
                },
            },

            // if country US and box price are over 45 USD
            {
                condition: ![
                    this.country() === 'US',
                    this.membership_shipping_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 4500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                },
            },

            // if country is US and customer is active subscriber and cart total > 45 USD
            {
                condition: ![
                    this.country() === 'US',
                    this.is_active_subscriber_customer(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 4500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                },
            },

            // if country is US and cart total > 50 USD
            {
                condition: ![
                    this.country() === 'US',
                    !this.membership_shipping_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal',
                    threshold: 7500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                },
            },

            // if country is not US and cart total > 45 USD
            {
                condition: ![
                    this.country() === 'GB',
                    this.membership_shipping_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal',
                    threshold: 4500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                },
            },

            // if country is not us and member pricing available and cart total > 75 USD
            {
                condition: ![
                    this.country() !== 'US',
                    this.membership_shipping_pricing_available(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 7500,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                },
            },

            // if country is not US and customer is active subscriber and cart total > 100 USD
            {
                condition: ![
                    this.country() !== 'US',
                    this.is_active_subscriber_customer(),
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 10000,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                },
            },

            // if country is not US and box price are over 100 USD
            {
                condition: ![
                    this.country() !== 'US',
                    this.subscription_items_total_usd() > 0,
                ]?.includes(false),
                settings: {
                    property: 'subscription_items_total_usd',
                    threshold: 10000,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'Add <strong>{{ free_shipping_away_amount }}</strong> more to your box to receive free shipping',
                    },
                },
            },

            // add rules from Rebuy
            ...(this.rebuy_free_shipping_rules || []),

            // if country is not US and cart total >= 150 USD
            {
                condition: ![
                    this.country() !== 'US',
                ]?.includes(false),
                settings: {
                    property: 'shippable_items_subtotal_usd',
                    threshold: 15000,
                    messages: {
                        reached: 'Congrats! Your items ship for free',
                        away: 'You`re <strong>{{ free_shipping_away_amount }}</strong> away from free shipping!',
                    },
                },
            },
        ]?.map(({ settings, ...rule }) => {
            const property = settings?.property;

            switch (property) {
                case 'subscription_lash_items_count': {
                    return {
                        ...rule,
                        settings: {
                            ...settings,
                            minimum: 0,
                            sort: 0,
                        },
                    };
                }
                case 'subtotal': {
                    return {
                        ...rule,
                        settings: {
                            ...settings,
                            minimum: window.Currency.convert(settings?.threshold || 0, this.currency(), 'USD'),
                            sort: window.Currency.convert(settings?.threshold || 0, this.currency(), 'USD'),
                        },
                    };
                }
                case 'subtotal_usd': {
                    return {
                        ...rule,
                        settings: {
                            ...settings,
                            minimum: settings?.threshold || 0,
                            sort: settings?.threshold || 0,
                        },
                    };
                }
                case 'shippable_items_subtotal': {
                    return {
                        ...rule,
                        settings: {
                            ...settings,
                            minimum: window.Currency.convert(((settings?.threshold || 0) + this.unshippable_items_subtotal()), this.currency(), 'USD'),
                            sort: window.Currency.convert((settings?.threshold || 0), this.currency(), 'USD'),
                        },
                    };
                }
                case 'shippable_items_subtotal_usd': {
                    return {
                        ...rule,
                        settings: {
                            ...settings,
                            minimum: (settings?.threshold || 0) + this.unshippable_items_subtotal_usd(),
                            sort: settings?.threshold || 0,
                        },
                    };
                }
                case 'subscription_items_total': {
                    return {
                        ...rule,
                        settings: {
                            ...settings,
                            minimum: window.Currency.convert((settings?.threshold || 0), this.currency(), 'USD'),
                            sort: window.Currency.convert((settings?.threshold || 0), this.currency(), 'USD'),
                        },
                    };
                }
                case 'subscription_items_total_usd': {
                    return {
                        ...rule,
                        settings: {
                            ...settings,
                            minimum: settings?.threshold || 0,
                            sort: settings?.threshold || 0,
                        },
                    };
                }
            }

            return {
                ...rule,
                settings: {
                    ...(settings || {}),
                    minimum: settings?.threshold || 0,
                    sort: settings?.threshold || 0,
                },
            };
        }) || [];
    },

    free_shipping_settings() {
        const free_shipping_rules = this.free_shipping_rules();
        if (!free_shipping_rules) {
            return null;
        }

        if (!free_shipping_rules?.length) {
            return null;
        }

        return free_shipping_rules?.find((settings) => {
            return !!settings?.condition;
        })?.settings || null;
    },

    is_free_shipping_reached() {
        const free_shipping_rules = this.free_shipping_rules();
        if (!free_shipping_rules) {
            return false;
        }

        if (!free_shipping_rules?.length) {
            return false;
        }

        return free_shipping_rules?.filter((free_shipping_rule) => {
            return !!free_shipping_rule?.condition;
        })?.map((free_shipping_rule) => {
            return this.calculate_away_amount(free_shipping_rule?.settings);
        })?.map((away) => {
            return away <= 0;
        })?.includes(true) || false;
    },

    free_shipping_rule_available() {
        return !!this.free_shipping_settings();
    },

    free_shipping_messages() {
        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return null;
        }

        const messages = free_shipping_settings?.messages;
        if (!messages) {
            return null;
        }

        return messages;
    },

    free_shipping_away_message() {
        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return 0;
        }

        const free_shipping_messages = this.free_shipping_messages();
        if (!free_shipping_messages) {
            return null;
        }

        const away_message = free_shipping_messages?.away;
        if (!away_message) {
            return null;
        }

        switch (free_shipping_settings?.property) {
            case 'shippable_items_subtotal': {
                return away_message.replaceAll('{{ free_shipping_away_amount }}', this.formatMoney(this.free_shipping_away_amount(), window.Shopify.money_format));
            }
            case 'subtotal': {
                return away_message.replaceAll('{{ free_shipping_away_amount }}', this.formatMoney(this.free_shipping_away_amount(), window.Shopify.money_format));
            }
            case 'subscription_items_total': {
                return away_message.replaceAll('{{ free_shipping_away_amount }}', this.formatMoney(this.free_shipping_away_amount(), window.Shopify.money_format));
            }
            case 'subscription_lash_items_count': {
                return away_message.replaceAll('{{ free_shipping_away_amount }}', this.free_shipping_away_amount());
            }
            default: {
                return away_message.replaceAll('{{ free_shipping_away_amount }}', this.formatMoney(window.Currency.convert(this.free_shipping_away_amount() / 100, 'USD', this.currency()) * 100, window.Shopify.money_format));
            }
        }
    },

    free_shipping_reached_message() {
        const free_shipping_messages = this.free_shipping_messages();
        if (!free_shipping_messages) {
            return null;
        }

        const reached_message = free_shipping_messages?.reached;
        if (!reached_message) {
            return null;
        }

        return reached_message;
    },

    calculate_away_amount(free_shipping_settings) {
        const threshold = free_shipping_settings?.threshold;
        if (!threshold) {
            return 0;
        }

        switch (free_shipping_settings?.property) {
            case 'subscription_lash_items_count': {
                return Math.max(Math.floor(threshold - this.subscription_lash_items_count()), 0);
            }
            case 'subtotal': {
                return Math.max(Math.floor(threshold - this.subtotal()), 0);
            }
            case 'subtotal_usd': {
                return Math.max(Math.floor(threshold - this.subtotal_usd()), 0);
            }
            case 'shippable_items_subtotal': {
                return Math.max(Math.floor(threshold - this.shippable_items_subtotal()), 0);
            }
            case 'shippable_items_subtotal_usd': {
                return Math.max(Math.floor(threshold - this.shippable_items_subtotal_usd()), 0);
            }
            case 'subscription_items_total': {
                return Math.max(Math.floor(threshold - this.subscription_items_total()), 0);
            }
            case 'subscription_items_total_usd': {
                return Math.max(Math.floor(threshold - this.subscription_items_total_usd()), 0);
            }
            default: {
                return Math.max(Math.floor(threshold - this.shippable_items_subtotal_usd()), 0);
            }
        }
    },

    free_shipping_away_amount() {
        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return 0;
        }

        return this.calculate_away_amount(free_shipping_settings);
    },

    free_shipping_percent_complete() {
        if (this.is_free_shipping_reached()) {
            return 100.0;
        }

        const free_shipping_settings = this.free_shipping_settings();
        if (!free_shipping_settings) {
            return 0;
        }

        const threshold = free_shipping_settings?.threshold;
        if (!threshold) {
            return 0;
        }

        return (threshold - this.free_shipping_away_amount()) / threshold * 100;
    },

    formatMoney(...args) {
        return formatMoney(...args);
    },
    toggleOptions(productId) {
        const optionsWrapper = document.querySelector(`.upsell-widget__options.upsell-widget__options--${productId}`);
        const button = document.querySelector(`.upsell-widget__info-right__button.upsell-widget__info-right__button--${productId}`);
        const overlay = document.querySelector('.upsell-widget__overlay');

        if (optionsWrapper && button && overlay) {
            optionsWrapper.classList.toggle('upsell-widget__options--show');
            button.classList.toggle('rotate');
            overlay.classList.toggle('upsell-widget__overlay--show');
        }
    },
    closeOptions() {
        setTimeout(() => {
            const optionsWrappers = document.querySelectorAll('.upsell-widget__options');
            const overlay = document.querySelector('.upsell-widget__overlay');
            const buttons = document?.querySelectorAll(`.upsell-widget__info-right__button`);

            optionsWrappers.forEach((optionsWrapper) => {
                optionsWrapper?.classList?.remove('upsell-widget__options--show');
                overlay?.classList?.remove('upsell-widget__overlay--show');
                buttons?.forEach((button) => {
                    button?.classList?.remove('rotate');
                });
            });
        }, 300);
    },

    presentment_price(selected_variant) {
        const currency = window?.Shopify?.currency?.active?.toLowerCase();
        if (!currency) {
            return this.cents(selected_variant.price);
        }

        if (currency === 'usd') {
            return this.cents(selected_variant.price);
        }

        const presentment_prices = selected_variant?.presentment_prices;
        if (!presentment_prices) {
            return 0;
        }

        const presentment_price = presentment_prices?.map((presentment_price) => {
            return presentment_price?.price;
        })?.find((price) => {
            return price?.currency_code?.toLowerCase() === window?.Shopify?.currency?.active?.toLowerCase();
        })?.amount;

        if (!presentment_price) {
            return this.cents(window.Currency.convert(selected_variant.price, 'USD', window.Shopify.currency.active) * 100);
        }

        return this.cents(presentment_price);
    },

    cents(price) {
        const converted_price = parseInt(price?.toString()?.replaceAll('.', '')?.replaceAll(',', '') || 0);
        if (!converted_price) {
            return 0;
        }

        return converted_price;
    },

    tiers(tiers = []) {
        const sorted_tiers = tiers?.map((tier) => {
            const type = tier?.type?.toLowerCase();

            switch (type) {
                case 'shipping': {
                    const free_shipping_settings = this.free_shipping_settings();

                    return {
                        ...tier,
                        minimum: (free_shipping_settings?.minimum || 0) / 100,
                        sort: (free_shipping_settings?.sort || 0) / 100,
                    };
                }
                default: {
                    return {
                        ...tier,
                        minimum: tier?.minimum || 0,
                        sort: tier?.minimum || 0,
                    };
                }
            }
        })?.sort((a, b) => {
            return (a?.sort || 0) > (b?.sort || 0) ? 1 : -1;
        }) || [];

        if (!sorted_tiers) {
            return [];
        }

        if (!sorted_tiers?.length) {
            return [];
        }

        return sorted_tiers;
    },

    log(data) {
        console.log(data);

        return data;
    },

    getLimitsSettings() {
        const limitRulesElement = document.getElementById('limit-rules-json');
        if (!limitRulesElement) {
            return null;
        }

        try {
            const rules = JSON.parse(limitRulesElement?.innerHTML?.trim() || '');

            return {
                name: "Order Rules",
                limit_rules: rules?.map((rule) => {
                    return {
                        ...rule,
                        minimum: parseInt(rule?.minimum) || 1,
                        maximum: parseInt(rule?.maximum) || Infinity,
                    };
                }) || [],
            };
        } catch (error) {
        }

        return null;
    },

    isItemCompatibleWithLimits(item) {
        const jsonData = this.getLimitsSettings();
        if (!jsonData) {
            return [];
        }

        const limitRules = jsonData?.limit_rules || [];
        if (!limitRules) {
            return [];
        }

        if (!limitRules?.length) {
            return [];
        }

        const rules = limitRules?.filter((rule) => {
            return [
                rule?.type === "variant" && `${rule?.id}` === `${item?.variant_id}`,
                rule?.type === "product" && `${rule?.id}` === `${item?.product_id}`,
            ].includes(true);
        });

        if (!rules) {
            return [];
        }

        if (!rules?.length) {
            return [];
        }

        const merged_rule = rules?.reduce((merged_rule, rule) => {
            return {
                minimum: Math.max(merged_rule?.minimum || 1, rule?.minimum || 1),
                maximum: Math.min(merged_rule?.maximum || Infinity, rule?.maximum || Infinity),
            };
        }, {
            minimum: 1,
            maximum: Infinity,
        });

        if (!merged_rule) {
            return [];
        }

        const minQuantity = merged_rule?.minimum || 1;
        const maxQuantity = merged_rule?.maximum || Infinity;

        if (item?.quantity > maxQuantity) {
            return [
                {
                    code: 'quantity_above_maximum',
                    message: `The maximum quantity allowed for this item is ${maxQuantity}. The item quantity has been automatically decreased.`,
                    quantity: maxQuantity,
                },
            ];
        }

        if (item?.quantity < minQuantity) {
            return [
                {
                    code: 'quantity_below_minimum',
                    message: `The minimum quantity allowed for this item is ${minQuantity}. The item quantity has been automatically increased.`,
                    quantity: minQuantity,
                },
            ];
        }

        return [];
    },

    isItemCompatibleWithLimitsMessage(item) {
        const jsonData = this.getLimitsSettings();
        if (!jsonData) {
            return [];
        }

        const limitRules = jsonData?.limit_rules || [];
        if (!limitRules) {
            return [];
        }

        if (!limitRules?.length) {
            return [];
        }

        const rules = limitRules?.filter((rule) => {
            return [
                rule?.type === "variant" && `${rule?.id}` === `${item?.variant_id}`,
                rule?.type === "product" && `${rule?.id}` === `${item?.product_id}`,
            ].includes(true);
        });

        if (!rules) {
            return [];
        }

        if (!rules?.length) {
            return [];
        }

        const merged_rule = rules?.reduce((merged_rule, rule) => {
            return {
                minimum: Math.max(merged_rule?.minimum || 1, rule?.minimum || 1),
                maximum: Math.min(merged_rule?.maximum || Infinity, rule?.maximum || Infinity),
            };
        }, {
            minimum: 1,
            maximum: Infinity,
        });

        if (!merged_rule) {
            return [];
        }

        const minQuantity = merged_rule?.minimum || 1;
        const maxQuantity = merged_rule?.maximum || Infinity;

        if (maxQuantity !== Infinity) {
            return [
                {
                    code: 'quantity_above_maximum',
                    message: `The maximum quantity allowed for this item is ${maxQuantity}.`,
                    quantity: maxQuantity,
                },
            ];
        }

        if (minQuantity > 1) {
            return [
                {
                    code: 'quantity_below_minimum',
                    message: `The minimum quantity allowed for this item is ${minQuantity}.`,
                    quantity: minQuantity,
                },
            ];
        }

        return [];
    },

    tier_progress(tier) {
        const subtotal = this.subtotal_usd();
        if (!subtotal) {
            return 0;
        }

        const minimum = (tier?.minimum || 0) * 100;
        if (!minimum) {
            return 100;
        }

        if (minimum <= subtotal) {
            return 100;
        }

        return subtotal / minimum * 100;
    },

    tier_completed(tier) {
        if (tier?.type?.toLowerCase() === 'shipping') {
            return this.is_free_shipping_reached();
        }

        const tier_progress = this.tier_progress(tier);
        if (!tier_progress) {
            return false;
        }

        return tier_progress >= 100;
    },

    completed_tiers(tiers = []) {
        return this.tiers(tiers)?.filter((tier) => {
            return !!this.tier_completed(tier);
        }) || [];
    },

    completed_tiers_count(tiers = []) {
        const completed_tiers = this.completed_tiers(tiers);
        if (!completed_tiers) {
            return 0;
        }

        if (!completed_tiers?.length) {
            return 0;
        }

        return completed_tiers?.length || 0;
    },

    tiers_count(tiers = []) {
      return tiers?.length || 0;
    },

    tier_remaining_amount(tier) {
        const subtotal = this.subtotal_usd() || 0;

        const minimum = (tier?.minimum || 0) * 100 || 0;
        if (!minimum) {
            return 0;
        }

        return parseInt((minimum - subtotal).toString());
    },

    tier_remaining_text(tier) {
        if (tier?.type?.toLowerCase() === 'shipping') {
            return this.free_shipping_away_message();
        }

        const product_amount_remaining_label_text = tier?.product_amount_remaining_label_text;
        if (!product_amount_remaining_label_text) {
            return null;
        }

        const remaining_amount = this.tier_remaining_amount(tier);

        return product_amount_remaining_label_text
            .replaceAll('{{remaining_amount}}', `<strong>${formatMoney(window.Currency.convert(remaining_amount, 'USD', window.Shopify.currency.active), window.Shopify.money_format)}</strong>`)
            .replaceAll('{{product_title}}', `<strong>${this.tier_default_product_title(tier)}</strong>`);
    },

    tier_reached_text(tier) {
        if (tier?.type?.toLowerCase() === 'shipping') {
            return this.free_shipping_reached_message();
        }

        const product_amount_reached_label_text = tier?.product_amount_reached_label_text;
        if (!product_amount_reached_label_text) {
            return null;
        }

        return product_amount_reached_label_text.replaceAll('{{product_title}}', `<strong>${this.tier_default_product_title(tier)}</strong>`);
    },

    tier_default_product(tier) {
        const products = this.tier_products(tier?.products || []);
        if (!products) {
            return null;
        }

        if (!products) {
            return null;
        }

        const product = products[0];
        if (!product) {
            return null;
        }

        return product;
    },

    tier_default_product_has_only_default_variant(tier) {
        const product = this.tier_default_product(tier);
        if (!product) {
            return false;
        }

        return !!product?.has_only_default_variant;
    },

    tier_default_product_title(tier) {
        const product = this.tier_default_product(tier);
        if (!product) {
            return null;
        }

        return product?.title || null;
    },

    tier_label(tier) {
        const type = tier?.type?.toLowerCase();

        switch(type) {
            case 'shipping': {
                return 'Free Shipping';
            }
            case 'product': {
                return this.tier_default_product_title(tier);
            }
        }

        return 'Free gift';
    },

    current_tier(tiers = []) {
        return this.tiers(tiers)?.find((tier) => {
            return !this.tier_completed(tier);
        }) || tiers[tiers.length - 1] || null;
    },

    current_tier_progress(tiers) {
        const current_tier = this.current_tier(tiers);
        if (!current_tier) {
            return 0;
        }

        return this.tier_progress(current_tier);
    },

    current_tier_completed(tiers) {
        const current_tier = this.current_tier(tiers);
        if (!current_tier) {
            return false;
        }

        return this.tier_completed(current_tier);
    },

    current_tier_remaining_amount(tiers) {
        const current_tier = this.current_tier(tiers);
        if (!current_tier) {
            return 0;
        }

        return this.tier_remaining_amount(current_tier);
    },

    current_tier_remaining_text(tiers) {
        const current_tier = this.current_tier(tiers);
        if (!current_tier) {
            return null;
        }

        return this.tier_remaining_text(current_tier);
    },

    current_tier_reached_text(tiers) {
        const current_tier = this.current_tier(tiers);
        if (!current_tier) {
            return null;
        }

        return this.tier_reached_text(current_tier);
    },

    tier_products(products = []) {
        if (!window?.Rebuy?.SmartCart?.settings?.progress_bar?.enabled) {
            return [];
        }

        if (!products) {
            return [];
        }

        if (!products?.length) {
            return [];
        }

        const products_with_available = products?.map((product) => {
            const variant_ids = product?.variant_ids || [];

            const variants = (product?.variants || [])?.filter((variant) => {
                if (!variant_ids) {
                    return true;
                }

                if (!variant_ids?.length) {
                    return true;
                }

                return !!variant_ids?.includes(variant?.id);
            })?.map((variant) => {
                return {
                    ...variant,
                    image: product?.images?.find((image) => {
                        return image?.id === variant?.image_id;
                    }) || null,
                };
            })?.map((variant) => {
                const inventory_policy = variant?.inventory_policy || 'deny';
                if (inventory_policy === 'continue') {
                    return {
                        ...variant,
                        available: true,
                    };
                }

                const inventory_quantity = variant?.inventory_quantity || 0;
                if (!inventory_quantity) {
                    return {
                        ...variant,
                        available: false,
                    };
                }

                return {
                    ...variant,
                    available: inventory_quantity > 0,
                };
            });

            return {
                ...product,
                variants,
                has_only_default_variant: this.isProductHasOnlyDefaultVariant(product) || variants?.length < 2,
            };
        }) || [];

        if (!products_with_available) {
            return [];
        }

        if (!products_with_available?.length) {
            return [];
        }

        const tier_selected_variants = this.tier_selected_variants || {};

        return products_with_available?.map((product) => {
            const selected_or_first_available_variant = product?.variants?.find((variant) => {
                return variant?.id === tier_selected_variants[product?.id];
            }) || product?.variants?.find((variant) => {
                return !!variant?.available;
            }) || product?.variants[0];

            return {
                ...product,
                selected_variant: selected_or_first_available_variant,
                selected_variant_id: selected_or_first_available_variant?.id,
                selected_or_first_available_variant,
                adding: false,
            };
        });
    },

    select_tier_product_variant(product_id, variant_id) {
        const tier_selected_variants = this.tier_selected_variants;

        if (!tier_selected_variants) {
            this.tier_selected_variants = {
                [product_id]: variant_id,
            };
        }

        this.tier_selected_variants = {
            ...(tier_selected_variants || {}),
            [product_id]: variant_id,
        };

        const vue = window['Rebuy']?.SmartCart?.view;
        if (!vue) {
            return;
        }

        vue?.$forceUpdate?.();
    },

    tier_selected_product_variant(product) {
        const tier_selected_variants = this.tier_selected_variants || {};

        return product?.variants?.find((variant) => {
            const tier_selected_variant_id = tier_selected_variants[product?.id];
            if (!tier_selected_variant_id) {
                return !!variant?.available;
            }

            return variant?.id === tier_selected_variant_id || !!variant?.available;
        }) || product?.variants[0];
    },

    earned_products(tiers = []) {
        if (!tiers) {
            return [];
        }

        if (!tiers?.length) {
            return [];
        }

        const product_tiers = tiers?.filter((tier) => {
            return tier?.type?.toLowerCase() === 'product';
        }) || [];

        if (!product_tiers) {
            return [];
        }

        if (!product_tiers?.length) {
            return [];
        }

        const completed_tiers = product_tiers?.filter((tier) => {
            return !!this.tier_completed(tier);
        });

        if (!completed_tiers) {
            return [];
        }

        if (!completed_tiers?.length) {
            return [];
        }

        return completed_tiers?.reduce((products, tier) => {
            const tier_products = this.tier_products(tier?.products || []);
            if (!tier_products) {
                return products;
            }

            if (!tier_products?.length) {
                return products;
            }

            return [
                ...(products || []),
                ...(tier_products?.reduce((products, product) => {
                    if (!product) {
                        return products;
                    }

                    return [
                        ...(products || []),
                        {
                            ...product,
                            tier,
                        },
                    ];
                }, []) || []),
            ];
        }, []) || [];
    },

    filtered_earned_products(tiers = []) {
        if (!tiers) {
            return [];
        }

        if (!tiers?.length) {
            return [];
        }

        const earned_products = this.earned_products(tiers);
        if (!earned_products) {
            return [];
        }

        if (!earned_products?.length) {
            return [];
        }

        const filtered_earned_products = earned_products?.filter((product) => {
            return !product?.has_only_default_variant && product?.variants?.length > 1;
        }) || [];

        const free_gift_items = this.free_gift_items();
        if (!free_gift_items) {
            return filtered_earned_products;
        }

        if (!free_gift_items?.length) {
            return filtered_earned_products;
        }

        const free_gift_item_ids = free_gift_items?.reduce((free_gift_item_ids, item) => {
            const product_id = item?.product_id;
            if (!product_id) {
                return free_gift_item_ids;
            }

            if (!!free_gift_item_ids?.includes(product_id)) {
                return free_gift_item_ids;
            }

            return [
                ...(free_gift_item_ids || []),
                product_id,
            ];
        }, []) || [];

        if (!free_gift_item_ids) {
            return filtered_earned_products;
        }

        if (!free_gift_item_ids?.length) {
            return filtered_earned_products;
        }

        return filtered_earned_products?.filter((product) => {
            return !free_gift_item_ids?.includes(product?.id);
        }) || [];
    },

    async add_free_gift(item) {
        try {
            await this.remove_free_gift_items();

            await addItem(item);
        } catch (error) {
        }
    },

    free_gift_items() {
        const regular_items = this.regular_items();
        if (!regular_items) {
            return [];
        }

        if (!regular_items?.length) {
            return [];
        }

        return regular_items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            if (!keys?.includes('_attribution')) {
                return false;
            }

            const attribution = properties['_attribution'];
            if (!attribution) {
                return false;
            }

            return !![
                'Rebuy Tiered Progress Bar',
                'Rebuy Gift with Purchase',
            ]?.map((attribution) => {
                return attribution?.toLowerCase();
            })?.includes(attribution?.toLowerCase());
        });
    },

    tier_free_gift_items(tier) {
        const free_gift_items = this.free_gift_items();
        if (!free_gift_items) {
            return [];
        }

        if (!free_gift_items?.length) {
            return [];
        }

        return free_gift_items?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const item_tier_id = properties['_tier'];
            if (!item_tier_id) {
                return false;
            }

            return `${item_tier_id}` === `${tier?.id}`;
        }) || [];
    },

    async remove_tier_gift_items(tier) {
        const tier_free_gift_items = this.tier_free_gift_items(tier);
        if (!tier_free_gift_items) {
            return;
        }

        if (!tier_free_gift_items?.length) {
            return;
        }

        await update(tier_free_gift_items?.reduce((updates, item) => {
            const key = item?.key;
            if (!key) {
                return updates;
            }

            return {
                ...updates,
                [key]: 0,
            };
        }, {}) || {});
    },

    tier_has_free_gift_items(tier) {
        const tier_free_gift_items = this.tier_free_gift_items(tier);
        if (!tier_free_gift_items) {
            return false;
        }

        return !!tier_free_gift_items?.length;
    },

    async remove_free_gift_items(tier_id = null) {
        const free_gift_items = this.free_gift_items();
        if (!free_gift_items) {
            return;
        }

        if (!free_gift_items?.length) {
            return;
        }

        if (!tier_id) {
            await update(free_gift_items?.reduce((updates, item) => {
                const key = item?.key;
                if (!key) {
                    return updates;
                }

                return {
                    ...updates,
                    [key]: 0,
                };
            }, {}) || {});
        }

        await update(free_gift_items?.reduce((updates, item) => {
            const key = item?.key;
            if (!key) {
                return updates;
            }

            const properties = item?.properties;
            if (!properties) {
                return updates;
            }

            const item_tier_id = properties['_tier'];
            if (!item_tier_id) {
                return updates;
            }

            return {
                ...updates,
                [key]: 0,
            };
        }, {}) || {});
    },

    async tier_add_free_gift(tier, item) {
        try {
            await this.remove_tier_gift_items(tier);

            await addItem({
                ...item,
                properties: {
                    ...(item?.properties || {}),
                    _attribution: 'Rebuy Tiered Progress Bar',
                    ...(!!tier?.id ? {
                        _tier: tier?.id,
                    } : {}),
                },
            });

            const checkbox = document.querySelector(`[data-tier-id="${tier?.id}"]`);
            if (!checkbox) {
                return;
            }

            checkbox.checked = false;
        } catch (error) {
        }
    },

    has_free_gift_items() {
        const free_gift_items = this.free_gift_items();
        if (!free_gift_items) {
            return false;
        }

        return !!free_gift_items?.length;
    },

    get bundle_settings() {
        const {
            bundles = [],
        } = this.discount_settings;

        if (!bundles) {
            return {};
        }

        if (!bundles?.length) {
            return {};
        }

        return bundles?.reduce((bundle_settings, bundle) => {
            const enabled = bundle?.enabled;
            if (!enabled) {
                return bundle_settings;
            }

            const name = bundle?.name;
            if (!name) {
                return bundle_settings;
            }

            bundle_settings[name] = bundle;

            return bundle_settings;
        }, {}) || {};
    },

    get grouped_bundles() {
        const {
            memberProductTags = [],
        } = this.discount_settings;

        const bundle_settings = this.bundle_settings;
        if (!bundle_settings) {
            return [];
        }

        const bundle_items = this.bundle_items();
        if (!bundle_items) {
            return [];
        }

        if (!bundle_items?.length) {
            return [];
        }

        const grouped_bundles = {};

        for (const item of bundle_items) {
            const properties = item?.properties;
            if (!properties) {
                continue;
            }

            const bundle_key = properties['_bundle_key'];
            if (!bundle_key) {
                continue;
            }

            if (!grouped_bundles[bundle_key]) {
                const bundle_product = properties['bundle_product'];
                if (!bundle_product) {
                    continue;
                }

                const item_settings = bundle_settings[bundle_product];
                if (!item_settings) {
                    continue;
                }

                grouped_bundles[bundle_key] = {
                    name: bundle_product,
                    parent: null,
                    children: [],
                    member_children: [],
                    addons: [],
                    addons_quantity: 0,
                    member_addons: [],
                    upsells: [],
                    subscription_upsells: [],
                    settings: item_settings,
                };
            }

            const bundle_main_product = properties['_bundle_main_product'];
            if (bundle_main_product) {
                grouped_bundles[bundle_key].parent = item;

                continue;
            }

            const subscription_upsell = properties['_subscription_upsell'];
            if (subscription_upsell) {
                grouped_bundles[bundle_key].subscription_upsells.push(item);

                continue;
            }

            const addon = properties['_addon'];
            if (addon) {
                grouped_bundles[bundle_key].addons.push(item);

                grouped_bundles[bundle_key].addons_quantity += item?.quantity || 0;

                continue;
            }

            const product = item?.product;
            if (!product) {
                grouped_bundles[bundle_key].children.push(item);

                continue;
            }

            const tags = product?.tags?.split(',')?.map((tag) => {
                return tag?.trim()?.toLowerCase();
            }) || [];

            if (!tags) {
                grouped_bundles[bundle_key].children.push(item);

                continue;
            }

            if (!tags?.length) {
                grouped_bundles[bundle_key].children.push(item);

                continue;
            }

            const member = !!memberProductTags?.map((tag) => {
                return tag?.toLowerCase();
            })?.map((tag) => {
                return !!tags?.includes(tag);
            })?.includes(true);

            if (!member) {
                grouped_bundles[bundle_key].children.push(item);

                continue;
            }

            grouped_bundles[bundle_key].member_children.push(item);
        }

        return Object.values(grouped_bundles) || [];
    },

    get bundle_membership_savings() {
        const grouped_bundles = this.grouped_bundles;
        if (!grouped_bundles) {
            return 0.0;
        }

        if (!grouped_bundles?.length) {
            return 0.0;
        }

        return grouped_bundles?.reduce((savings, bundle) => {
            const settings = bundle?.settings;
            if (!settings) {
                return savings;
            }

            const {
                enable_parent_discount = false,
                enable_children_discount = false,
            } = settings;

            if (enable_parent_discount) {
                const parent_member_discount_value = settings?.parent_member_discount_value || settings?.parent_discount_value || 0;

                const diff = parent_member_discount_value - (settings?.parent_discount_value || 0);

                savings += (bundle?.parent?.price || 0) * (bundle?.parent?.quantity || 1) * diff / 100;
            }

            if (enable_children_discount) {
                const children_member_discount_value = settings?.children_member_discount_value || settings?.children_discount_value || 0;

                const diff = children_member_discount_value - (settings?.children_discount_value || 0);

                savings += bundle?.member_children?.reduce((savings, item) => {
                    return savings + (item?.price || 0) * (item?.quantity || 1) * diff / 100;
                }, 0.0) || 0.0;
            }

            return savings;
        }, 0.0) || 0.0;
    },

    membership_savings() {
        const {
            memberDiscountValue = 0,
            memberProductTags = [],
            subscriptionTiers = {},
            subscriptionTierProductTags = [],
        } = this.discount_settings;

        if (!memberProductTags) {
            return this.bundle_membership_savings;
        }

        if (!memberProductTags?.length) {
            return this.bundle_membership_savings;
        }

        const items = window.Rebuy.SmartCart.items();
        if (!items) {
            return this.bundle_membership_savings;
        }

        if (!items?.length) {
            return this.bundle_membership_savings;
        }

        const frequencies = subscriptionTiers?.reduce((frequencies, subscriptionTier) => {
            const frequency_interval = subscriptionTier?.frequency_interval;
            if (!frequency_interval) {
                return frequencies;
            }

            const frequency_unit = subscriptionTier?.frequency_unit;
            if (!frequency_unit) {
                return frequencies;
            }

            return {
                ...(frequencies || {}),
                [`${frequency_interval} ${frequency_unit}`]: {
                    ...subscriptionTier,
                    discount_levels: [
                        ...(subscriptionTier?.discount_levels || []),
                    ]?.sort((previos, next) => {
                        return (next?.minimum_quantity || 0) - (previos?.minimum_quantity || 0);
                    }) || []
                },
            };
        }, {}) || {};

        const subscription_items = items?.filter((item) => {
            return !!item?.selling_plan_allocation?.selling_plan;
        }) || [];

        const subscription_lash_items = subscription_items?.filter((item) => {
            const tags = item?.product?.tags?.split(',')?.map((tag) => {
                return tag?.trim()?.toLowerCase();
            }) || [];

            if (!tags) {
                return false;
            }

            if (!tags?.length) {
                return false;
            }

            return !!subscriptionTierProductTags?.map((tag) => {
                return tag?.toLowerCase();
            })?.map((tag) => {
                return !!tags?.includes(tag);
            })?.includes(true);
        }) || [];

        const total_subscription_lash_quantity = subscription_lash_items?.reduce((total, item) => {
            return total + (item?.quantity || 0);
        }, 0) || 0;

        const retail_items = items?.filter((item) => {
            const product = item?.product;
            if (!product) {
                return false;
            }

            const tags = product?.tags?.split(',')?.map((tag) => {
                return tag?.trim()?.toLowerCase();
            }) || [];

            if (!tags) {
                return false;
            }

            if (!tags?.length) {
                return false;
            }

            return !!memberProductTags?.map((tag) => {
                return tag?.toLowerCase();
            })?.map((tag) => {
                return !!tags?.includes(tag);
            })?.includes(true);
        });

        if (!retail_items) {
            return 0.0;
        }

        if (!retail_items?.length) {
            return 0.0;
        }

        const savings = retail_items?.reduce((savings, item) => {
            const properties = item?.properties;
            if (!properties) {
                return savings;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return savings + (item?.quantity || 1) * (item?.price || 0.0) * memberDiscountValue / 100.0;
            }

            if (!keys?.length) {
                return savings + (item?.quantity || 1) * (item?.price || 0.0) * memberDiscountValue / 100.0;
            }

            if (!!properties['_subscription_upsell']) {
                return savings;
            }

            if (!!properties['_free_gift']) {
                return savings;
            }

            if (!!properties['_bundle_key']) {
                return savings;
            }

            const selling_plan = item?.selling_plan_allocation?.selling_plan;
            if (!!selling_plan) {
                const regex = /(?<order_interval_frequency>\d+)(\s+)?(?<order_interval_unit_type>day|week|month|year)/gm;
                const rechargeData = regex?.exec(selling_plan?.name?.toLowerCase())?.groups;

                const frequency = `${rechargeData?.order_interval_frequency} ${rechargeData?.order_interval_unit_type}`;
                if (!frequency) {
                    return savings;
                }

                const tier = frequencies[frequency];
                if (!tier) {
                    return savings;
                }

                const discount_levels = tier?.discount_levels || [];
                if (!discount_levels) {
                    return savings;
                }

                if (!discount_levels?.length) {
                    return savings;
                }

                const current_discount_level = discount_levels?.find((discount_level) => {
                    return total_subscription_lash_quantity >= discount_level?.minimum_quantity;
                }) || null;

                if (!current_discount_level) {
                    return savings;
                }

                const savings_discount = (current_discount_level?.member_discount_value || 0) - (current_discount_level?.discount_value || 0);
                if (!savings_discount) {
                    return savings;
                }

                const item_savings = (item?.price || 0.0) * (item?.quantity || 1) * savings_discount / 100.0;
                if (!item_savings) {
                    return savings;
                }

                return savings + item_savings;
            }

            if (!memberDiscountValue) {
                return savings;
            }

            const item_savings = (item?.quantity || 1) * (item?.price || 0.0) * memberDiscountValue / 100.0;
            if (!item_savings) {
                return savings;
            }

            return savings + item_savings;
        }, 0.0) || 0.0;

        if (!savings) {
            return this.bundle_membership_savings;
        }

        return savings + this.bundle_membership_savings;
    },

    closed_membership_widget() {
        try {
            return Cookies.get('membership_widget_closed') === 'true' || false;
        } catch (error) {

        }

        return false;
    },

    close_membership_widget(event) {
        Cookies.set('membership_widget_closed', 'true', { expires: 1 });

        const target = event?.target?.closest('[data-membership-widget]');
        if (!target) {
            return;
        }

        target?.classList?.add('minicart-membership-widget--hidden');
    },

    buyMoreSaveMoreLevel() {
        const {
            buyMoreSaveMoreLevel,
        } = this.discount_settings;

        if (!buyMoreSaveMoreLevel) {
            return 'LINE_ITEM';
        }

        return buyMoreSaveMoreLevel;
    },

    buyMoreSaveMoreGroups() {
        const buyMoreSaveMoreGroups = {};
        const buyMoreSaveMoreLevel = this.buyMoreSaveMoreLevel() || "LINE_ITEM";

        const regular_items = this.regular_items();
        if (!regular_items) {
            return {};
        }

        if (!regular_items?.length) {
            return {};
        }

        for (const item of regular_items) {
            const properties = item?.properties;
            if (!properties) {
                continue;
            }

            const attribution = properties['_attribution'];
            if (!attribution) {
                continue;
            }

            switch (attribution) {
                case "Rebuy Buy More Save More":
                case "Removed Buy More Save More": {
                    let id = "LINE_ITEM";

                    switch (buyMoreSaveMoreLevel) {
                        case "PRODUCT_VARIANT": {
                            id = item?.variant_id;
                        }
                        break;
                        case "PRODUCT": {
                            id = item?.product_id;
                        }
                        break;
                        case "CART": {
                            id = "CART";
                        }
                        break;
                        default: {
                            id = item?.key;
                        }
                    }

                    if (!id) {
                        continue;
                    }

                    if (!buyMoreSaveMoreGroups[id]) {
                        buyMoreSaveMoreGroups[id] = {
                            id,
                            items: [],
                            quantity: 0,
                        };
                    }

                    buyMoreSaveMoreGroups[id].quantity += item?.quantity || 0;
                    buyMoreSaveMoreGroups[id].items.push(item);
                }
            }
        }

        return buyMoreSaveMoreGroups;
    }
};

window.RebuyUtils = window.RebuyUtils || RebuyUtils;
window.RebuyUtils.products = {};

document.addEventListener('click', async (event) => {
    const target = event?.target?.closest('[data-minicart-opener]');
    if (!target) {
        return;
    }

    const Rebuy = await windowVariableLoaded('Rebuy');
    if (!Rebuy) {
        return;
    }

    const SmartCart = Rebuy?.SmartCart;
    if (!SmartCart) {
        return;
    }

    SmartCart?.show();
});

document.addEventListener(EVENT_MINICART_OPEN, async () => {
    const Rebuy = await windowVariableLoaded('Rebuy');
    if (!Rebuy) {
        return;
    }

    const SmartCart = Rebuy?.SmartCart;
    if (!SmartCart) {
        return;
    }

    SmartCart?.show();
});

document.addEventListener(EVENT_MINICART_CLOSE, async () => {
    const Rebuy = await windowVariableLoaded('Rebuy');
    if (!Rebuy) {
        return;
    }

    const SmartCart = Rebuy?.SmartCart;
    if (!SmartCart) {
        return;
    }

    SmartCart?.hide();
});

export const limitItemQuantities = async () => {
    const Rebuy = await windowVariableLoaded('Rebuy');
    if (!Rebuy) {
        return;
    }

    if (typeof Rebuy?.SmartCart?.items !== 'function') {
        return;
    }

    const cartItems = Rebuy.SmartCart.items();
    if (!cartItems) {
        return;
    }

    if (!cartItems?.length) {
        return;
    }

    cartItems.forEach((item) => {
        if ([
            !!window.RebuyUtils.is_monthly_box_item(item),
            !!window.RebuyUtils.is_bundle_parent(item),
            !!window.RebuyUtils.is_bundle_child(item),
        ]?.includes(true)) {
            return;
        }

        const errors = window.RebuyUtils.isItemCompatibleWithLimits(item);
        if (!errors) {
            return;
        }

        if (!errors?.length) {
            return;
        }

        if (typeof Rebuy?.Cart?.setItemQuantity !== 'function') {
            return;
        }

        errors?.forEach((error) => {
            const code = error?.code;
            if (!code) {
                return;
            }

            const message = error?.message;
            if (!message) {
                return;
            }

            const quantity = error?.quantity;
            if (!quantity) {
                return;
            }

            Rebuy.Cart.setItemQuantity(item, quantity);
        });
    });
};

document.addEventListener(REBUY_CART_READY_EVENT, async () => {
    await limitItemQuantities();
});
document.addEventListener(REBUY_CART_CHANGE_EVENT, async () => {
    await limitItemQuantities();

    initAnnouncementSliders();
});

document.addEventListener(REBUY_CART_READY_EVENT, async (event) => {
    const { cart = null } = event.detail.cart;
    if (!cart) {
        return;
    }

    const buy_more_save_more = window?.Rebuy?.SmartCart?.settings?.buy_more_save_more || null;
    if (!buy_more_save_more) {
        return;
    }

    const enabled = buy_more_save_more?.enabled;
    if (!enabled) {
        return;
    }

    const discount_products = buy_more_save_more?.discount_products || [];
    if (!discount_products) {
        return;
    }

    if (!discount_products?.length) {
        return;
    }

    const discount_variant_ids = discount_products?.reduce((discount_variant_ids, product) => {
        return [
            ...(discount_variant_ids || []),
            ...(product?.variant_id || product?.json?.variants?.map(({ id }) => {
                return id;
            }) || []),
        ];
    }, []) || [];

    if (!discount_variant_ids) {
        return;
    }

    if (!discount_variant_ids?.length) {
        return;
    }

    const items = cart?.items || [];
    if (!items) {
        return;
    }

    if (!items?.length) {
        return;
    }

    const buy_more_get_more_items = items?.filter((item) => {
        return !!discount_variant_ids?.includes(item?.variant_id);
    }) || [];

    if (!buy_more_get_more_items) {
        return;
    }

    if (!buy_more_get_more_items?.length) {
        return;
    }

    const wrong_buy_more_get_more_items = buy_more_get_more_items?.filter((item) => {
        const properties = item?.properties || {};

        const keys = Object.keys(properties);
        if (!keys) {
            return true;
        }

        if (!keys?.length) {
            return true;
        }

        if (!!keys?.includes('_box_handle')) {
            return false;
        }

        if (!!keys?.includes('_rb_id')) {
            return false;
        }

        if (!!keys?.includes('_rc_bundle')) {
            return false;
        }

        if (!!keys?.includes('_inveterate_subscription')) {
            return false;
        }

        if (!!keys?.includes('_free_gift')) {
            return false;
        }

        if (!!keys?.includes('_bundle_key')) {
            return false;
        }


        const selling_plan_id = item?.selling_plan_allocation?.selling_plan?.id;
        if (selling_plan_id) {
            return false;
        }

        if (!keys?.includes('_attribution')) {
            return true;
        }

        return false;
    }) || [];

    if (!wrong_buy_more_get_more_items) {
        return;
    }

    if (!wrong_buy_more_get_more_items?.length) {
        return;
    }

    const updates = wrong_buy_more_get_more_items?.reduce((updates, item) => {
        return [
            ...(updates || []),
            {
                id: item?.variant_id,
                quantity: item?.quantity,
                properties: {
                    ...(item?.properties || {}),
                    "_attribution": "Rebuy Buy More Save More",
                    "_source": "Rebuy",
                },
                selling_plan: item?.selling_plan_allocation?.selling_plan?.id || null,
            }
        ];
    }, []);

    if (!updates) {
        return;
    }

    if (!updates?.length) {
        return;
    }

    await update(wrong_buy_more_get_more_items?.reduce((updates, item) => {
        return {
            ...(updates || {}),
            [item?.key]: 0,
        };
    }, {}));

    await addItems(updates);
});

document.addEventListener(REBUY_CART_CHANGE_EVENT, async (event) => {
    const { cart = null } = event.detail.cart;
    if (!cart) {
        return;
    }

    const buy_more_save_more = window?.Rebuy?.SmartCart?.settings?.buy_more_save_more || null;
    if (!buy_more_save_more) {
        return;
    }

    const enabled = buy_more_save_more?.enabled;
    if (!enabled) {
        return;
    }

    const discount_products = buy_more_save_more?.discount_products || [];
    if (!discount_products) {
        return;
    }

    if (!discount_products?.length) {
        return;
    }

    const discount_variant_ids = discount_products?.reduce((discount_variant_ids, product) => {
        return [
            ...(discount_variant_ids || []),
            ...(product?.variant_id || product?.json?.variants?.map(({ id }) => {
                return id;
            }) || []),
        ];
    }, []) || [];

    if (!discount_variant_ids) {
        return;
    }

    if (!discount_variant_ids?.length) {
        return;
    }

    const items = cart?.items || [];
    if (!items) {
        return;
    }

    if (!items?.length) {
        return;
    }

    const buy_more_get_more_items = items?.filter((item) => {
        return !!discount_variant_ids?.includes(item?.variant_id);
    }) || [];

    if (!buy_more_get_more_items) {
        return;
    }

    if (!buy_more_get_more_items?.length) {
        return;
    }

    const wrong_buy_more_get_more_items = buy_more_get_more_items?.filter((item) => {
        const properties = item?.properties || {};

        const keys = Object.keys(properties);
        if (!keys) {
            return true;
        }

        if (!keys?.length) {
            return true;
        }

        if (!!keys?.includes('_box_handle')) {
            return false;
        }

        if (!!keys?.includes('_rb_id')) {
            return false;
        }

        if (!!keys?.includes('_rc_bundle')) {
            return false;
        }

        if (!!keys?.includes('_inveterate_subscription')) {
            return false;
        }

        if (!!keys?.includes('_free_gift')) {
            return false;
        }

        if (!!keys?.includes('_bundle_key')) {
            return false;
        }

        const selling_plan_id = item?.selling_plan_allocation?.selling_plan?.id;
        if (selling_plan_id) {
            return false;
        }

        if (!keys?.includes('_attribution')) {
            return true;
        }

        return false;
    }) || [];

    if (!wrong_buy_more_get_more_items) {
        return;
    }

    if (!wrong_buy_more_get_more_items?.length) {
        return;
    }

    const updates = wrong_buy_more_get_more_items?.reduce((updates, item) => {
        return [
            ...(updates || []),
            {
                id: item?.variant_id,
                quantity: item?.quantity,
                properties: {
                    ...(item?.properties || {}),
                    "_attribution": "Rebuy Buy More Save More",
                    "_source": "Rebuy",
                },
                selling_plan: item?.selling_plan_allocation?.selling_plan?.id || null,
            }
        ];
    }, []);

    if (!updates) {
        return;
    }

    if (!updates?.length) {
        return;
    }

    await update(wrong_buy_more_get_more_items?.reduce((updates, item) => {
        return {
            ...(updates || {}),
            [item?.key]: 0,
        };
    }, {}));

    await addItems(updates);
});

document.addEventListener(REBUY_CART_ADD_EVENT, async () => {
    const buttons = document.querySelectorAll(`[data-tiered-progress-bar-product-submit]`);
    if (!buttons) {
        return;
    }

    if (!buttons?.length) {
        return;
    }

    buttons.forEach((button) => {
        button.disabled = false;
        button?.classList?.remove('loading');
    });
});

document.addEventListener('rebuy.ready', async (event) => {
    console.log('rebuy.ready', event);
});

document.addEventListener('rebuy.productsChange', async (event) => {
    const widget = event?.detail?.widget;
    if (!widget) {
        return;
    }

    const ShopifyStorefrontHelper = await windowVariableLoaded('ShopifyStorefrontHelper');
    if (!ShopifyStorefrontHelper) {
        return;
    }

    if (typeof ShopifyStorefrontHelper !== 'function') {
        return;
    }

    const storefrontHelper = new ShopifyStorefrontHelper();
    if (!storefrontHelper) {
        return;
    }

    // loop over products in the widget and enrich product json
    widget?.data?.products?.forEach((product) => {
        storefrontHelper.updateProductJSON(product);

        const productId = parseInt(product?.id);
        if (!productId) {
            return;
        }

        console.log('rebuy.productsChange', product);

        window.RebuyUtils.products[productId] = {
            selected_variant: product?.selected_variant,
            option1: product?.selected_variant?.option1,
            option2: product?.selected_variant?.option2,
            option3: product?.selected_variant?.option3,
        };
    });
});

export default RebuyUtils;
